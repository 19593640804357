<template>
  <div class="c-transaction-details-container">
    <el-row :gutter="20">
      <el-col :span="18" :offset="0">
        <el-card class="box-card u-bottom-margin--2x u-centered">
          <div
            class="back-btn u-color--normal-text-color u-font--bold u-font--medium"
          >
            <span @click="goBack">
              <i class="el-icon-arrow-left"></i>
              {{ $t("TransactionDetailsPageBackBtn") }}
            </span>
          </div>
          <el-checkbox-group border v-model="selectedDistributers">
            <template v-for="(distributer, key) in orderDistributers">
              <el-checkbox
                v-if="canDistibuterBeShowen(distributer)"
                :key="key"
                border
                :label="distributer.id.toString()"
                class="checkbox-item"
                @change="handleDistributersChange"
              >
                <div class="u-display-flex u-align-items--center">
                  <span
                    v-if="
                      distributer.areas.includes(
                        transactionDetails.user.area.id
                      )
                    "
                  >
                    *
                  </span>
                  <div
                    :class="`small-circle blacklist ${
                      $store.state.isRTL
                        ? 'u-right-margin--2x'
                        : 'u-left-margin--2x'
                    }`"
                    v-if="distributer.blacklist"
                  ></div>
                  <div
                    :class="`small-circle original ${
                      $store.state.isRTL
                        ? 'u-right-margin--2x'
                        : 'u-left-margin--2x'
                    }`"
                    v-if="distributer.original"
                  ></div>
                  <div
                    :class="`small-circle receipt ${
                      $store.state.isRTL
                        ? 'u-right-margin--2x'
                        : 'u-left-margin--2x'
                    }`"
                    v-if="distributer.receipt"
                  ></div>
                  <span
                    :class="
                      $store.state.isRTL
                        ? 'u-right-margin--1x'
                        : 'u-left-margin--1x'
                    "
                  >
                    {{ distributer.name }}</span
                  >
                </div>
              </el-checkbox>
            </template>
          </el-checkbox-group>

          <div
            class="u-direction--rtl u-font--tiny u-color--ink-lightest u-top-margin--1x u-right"
          >
            <span
              >* {{ $t("TransactionDetailsPageDistributerFromSameArea") }}</span
            >
          </div>

          <div
            class="u-direction--rtl u-font--tiny u-color--ink-lightest u-right u-display-flex u-align-items--center"
          >
            <div class="small-circle blacklist u-left-margin--1x"></div>
            <span> {{ $t("TransactionDetailsPageBlackListLabel") }} </span>
          </div>
          <div
            class="u-direction--rtl u-font--tiny u-color--ink-lightest u-right u-display-flex u-align-items--center"
          >
            <div class="small-circle original u-left-margin--1x"></div>
            <span> {{ $t("TransactionDetailsPageOriginalLabel") }} </span>
          </div>
          <div
            class="u-direction--rtl u-font--tiny u-color--ink-lightest u-right u-display-flex u-align-items--center"
          >
            <div class="small-circle receipt u-left-margin--1x"></div>
            <span>
              {{ $t("TransactionDetailsPageDistributerReceiptLabel") }}
            </span>
          </div>
        </el-card>
        <el-card :body-style="{ padding: '20px' }">
          <div class="reciept-container">
            <el-collapse accordion>
              <el-collapse-item name="1">
                <template slot="title">
                  <div class="c-reciept-header">
                    <div>
                      <div class="c-header-title u-font--large u-font--bold">
                        <span>{{
                          $t("TransactionDetailsPageOrderSummaryTitle")
                        }}</span>
                        <span
                          v-if="
                            transactionDetails.isAutoAssigned &&
                            transactionDetails.isAutoAssigned == 1 &&
                            transactionDetails.state == 'pending'
                          "
                        >
                          <el-button
                            size="mini"
                            type="primary"
                            :class="
                              $store.state.isRTL
                                ? 'u-right-margin--1x'
                                : 'u-left-margin--1x'
                            "
                            @click="stopAutoAssignmentClicked"
                            style="border: none"
                            >{{
                              $t(
                                "TransactionDetailsPageStopAutoAssignmentButtonLabel"
                              )
                            }}</el-button
                          ></span
                        >
                      </div>
                      <div class="c-header-tags">
                        <div
                          v-if="transactionDetails.method"
                          class="header-tag"
                        >
                          {{ $t("TransactionDetailsPageHeaderTagPayingLabel") }}
                          {{ transactionDetails.method }}
                        </div>
                        <div
                          v-if="transactionDetails.bundle"
                          class="header-tag"
                        >
                          {{
                            $t("TransactionDetailsPageHeaderTagDurationLabel")
                          }}
                          {{ transactionDetails.bundle.paybackDuration }}
                        </div>
                        <div
                          v-if="transactionDetails.bundle"
                          class="header-tag"
                        >
                          {{
                            $t("TransactionDetailsPageHeaderTagPremiumLabel")
                          }}
                          {{
                            transactionDetails.bundle.premiumPercentage * 100
                          }}
                        </div>
                        <div
                          v-if="
                            transactionDetails.totalAmount >
                            transactionDetails.totalOriginalAmount
                          "
                          class="header-tag"
                        >
                          {{
                            $t(
                              "TransactionDetailsPageHeaderTagPriceDifferenceLabel"
                            )
                          }}
                          {{
                            (
                              transactionDetails.totalAmount -
                              transactionDetails.totalOriginalAmount
                            ).toLocaleString()
                          }}
                          EGP
                          <span class="u-font--bold u-font--medium"
                            >({{
                              (((transactionDetails.totalAmount -
                                transactionDetails.totalOriginalAmount) /
                                transactionDetails.totalOriginalAmount) *
                                100)
                                | fotmatFloatValues
                            }}%)</span
                          >
                        </div>
                        <div class="header-tag" v-if="transactionDetails.items">
                          {{
                            $t(
                              "TransactionDetailsPageHeaderTagItemsLengthLabel",
                              [transactionDetails.items.length]
                            )
                          }}
                        </div>
                        <div
                          v-if="cancelledOrderProps.cancelledItemsCount"
                          class="header-tag--red"
                        >
                          {{
                            $t(
                              "TransactionDetailsPageHeaderTagCancelationAmount"
                            )
                          }}

                          {{ cancelledOrderProps.amount.toLocaleString() }} EGP
                          ({{
                            cancelledOrderProps.amountPercentge
                              | fotmatFloatValues
                          }}%)
                        </div>
                        <div
                          v-if="transactionDetails.promocode"
                          class="header-tag--green"
                        >
                          {{ $t("TransactionDetailsPageHeaderTagPromoLabel") }}
                          {{ transactionDetails.promocode.code }}
                        </div>
                        <div
                          v-if="transactionDetails.promocode"
                          class="header-tag--green"
                        >
                          {{ transactionDetails.promocode.discount_amount }} EGP
                        </div>
                        <!-- Billing Details -->

                        <div
                          v-if="
                            transactionDetails.promocode &&
                            transactionDetails.billingDetails
                          "
                          class="header-tag--red"
                        >
                          {{ transactionDetails.billingDetails[0].text }} :
                          {{ transactionDetails.billingDetails[0].value }}
                        </div>

                        <div
                          v-if="
                            transactionDetails.promocode &&
                            transactionDetails.billingDetails
                          "
                          class="header-tag--green"
                        >
                          {{ transactionDetails.billingDetails[2].text }} :
                          {{ transactionDetails.billingDetails[2].value }}
                        </div>
                        <div
                          v-if="
                            transactionDetails.isAutoAssigned &&
                            transactionDetails.isAutoAssigned == 1
                          "
                          class="header-tag--blue"
                        >
                          {{
                            $t(
                              "TransactionDetailsPageHeaderTagAutoAssignedLabel"
                            )
                          }}
                        </div>
                      </div>
                    </div>

                    <div class="u-display-flex">
                      <div class="header-tag--valid">
                        {{
                          $t("TransactionDetailsPageHeaderTagTotalCashLabel", [
                            transactionDetails.totalOriginalAmount,
                          ])
                        }}
                        <span class="u-font--small u-font--normal"> EGP</span>
                      </div>
                      <div
                        v-if="
                          transactionDetails.totalOriginalAmount &&
                          transactionDetails.method == `credit`
                        "
                        class="header-tag"
                      >
                        {{
                          $t(
                            "TransactionDetailsPageHeaderTagTotalCreditLabel",
                            [transactionDetails.creditTotalAmount]
                          )
                        }}
                        EGP
                      </div>
                    </div>
                  </div>
                </template>
                <div class="card-body">
                  <el-table
                    :data="transactionDetails.items"
                    style="width: 100%"
                    row-class-name="no-hover "
                    cell-class-name="t-coulumn"
                  >
                    <el-table-column
                      prop="product.name"
                      :label="
                        $t(
                          'TransactionDetailsPageSummaryProductDetailsColumnTitle'
                        )
                      "
                      width="360"
                    >
                      <template slot-scope="scope">
                        <div class="u-display-flex u-align-items--center">
                          <div class="u-right-margin--2x">
                            <img
                              width="50"
                              height="50"
                              style="object-fit: scale-down"
                              :src="scope.row.product.image"
                            />
                          </div>
                          <div class="u-direction--rtl">
                            <div>
                              {{ scope.row.product.name }}
                            </div>
                            <div class="u-display-flex u-direction--ltr">
                              <div
                                class="u-right-padding--half-x u-color--small-text-color"
                              >
                                {{ scope.row.product.unit }}
                              </div>
                              <div
                                class="u-direction--rtl u-color--small-text-color"
                              >
                                {{ scope.row.product.itemsPerUnit }}
                              </div>
                            </div>
                            <div
                              class="u-font--xsmall u-color--small-text-color"
                            >
                              ID:
                              <strong>{{ scope.row.productId }}</strong>
                            </div>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="originalQuantity"
                      :label="
                        $t('TransactionDetailsPageSummaryQuantityColumnTitle')
                      "
                      width="100"
                    ></el-table-column>
                    <el-table-column
                      prop="originalPrice"
                      :label="
                        $t('TransactionDetailsPageSummaryPriceColumnTitle')
                      "
                      width="100"
                    ></el-table-column>
                    <el-table-column
                      prop="provider.name"
                      :label="
                        $t(
                          'TransactionDetailsPageSummaryProviderNameColumnTitle'
                        )
                      "
                    ></el-table-column>
                    <el-table-column prop="item" label="">
                      <template slot-scope="scope">
                        <div
                          v-if="
                            scope.row.isFaturaProduct &&
                            scope.row.isFaturaProduct == 1
                          "
                        >
                          {{
                            $t(
                              "TransactionDetailsPageSummaryFaturaProductLabel"
                            )
                          }}
                          <emoji
                            style="padding-left: 5px"
                            :emoji="{ id: 'star', skin: 1 }"
                            :size="15"
                          />
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div
            v-for="(receipt, key) in receipts"
            :key="key"
            class="reciept-container--item"
          >
            <el-collapse accordion>
              <el-collapse-item name="1">
                <template slot="title">
                  <div class="c-reciept-header">
                    <div>
                      <div class="c-header-title u-font--large u-font--bold">
                        {{ receipt.receiptDistributer.name }}
                      </div>
                      <div class="c-header-tags">
                        <div
                          v-if="
                            transactionDetails.totalAmount >
                            transactionDetails.totalOriginalAmount
                          "
                          class="header-tag"
                        >
                          {{
                            $t(
                              "TransactionDetailsPageReceiptHeaderTagPriceDifferenceLabel"
                            )
                          }}
                          {{
                            (
                              transactionDetails.totalAmount -
                              transactionDetails.totalOriginalAmount
                            ).toLocaleString()
                          }}
                          EGP
                          <span class="u-font--bold u-font--medium"
                            >({{
                              (((transactionDetails.totalAmount -
                                transactionDetails.totalOriginalAmount) /
                                transactionDetails.totalOriginalAmount) *
                                100)
                                | fotmatFloatValues
                            }}%)</span
                          >
                        </div>
                        <div v-if="receipt.receiptStatus" class="header-tag">
                          {{ receipt.receiptStatus }}
                        </div>
                        <div v-if="receipt.undeliver_reason" class="header-tag">
                          {{
                            $t(
                              "TransactionDetailsPageReceiptUnDeliverReasonLabel",
                              [receipt.undeliver_reason]
                            )
                          }}
                        </div>

                        <div v-if="receipt.items" class="header-tag">
                          {{
                            $t(
                              "TransactionDetailsPageReceiptItemsLengthLabel",
                              [receipt.items.length]
                            )
                          }}
                        </div>

                        <div
                          v-if="receipt.receiptDistributer.minOrder"
                          class="header-tag"
                        >
                          {{
                            $t(
                              "TransactionDetailsPageReceiptHeaderTagMinOrderLabel"
                            )
                          }}
                          <span class="u-font--bold u-font--medium">{{
                            receipt.receiptDistributer.minOrder.toLocaleString()
                          }}</span>
                          EGP
                        </div>
                        <div
                          v-if="
                            receipt.receiptStatus &&
                            receipt.receiptStatus != 'pending'
                          "
                          class="header-tag"
                        >
                          {{
                            $t(
                              "TransactionDetailsPageReceiptHeaderTagActualValueLabel",
                              [
                                receipt.actualAmount
                                  ? receipt.actualAmount
                                  : "null",
                              ]
                            )
                          }}
                        </div>
                        <div v-if="receipt.receiptStatus" class="header-tag">
                          {{
                            $t(
                              "TransactionDetailsPageReceiptHeaderTagUpdatedAtLabel"
                            )
                          }}
                          {{
                            receipt.updatedAt | moment("DD MMM YYYY  h:mm a")
                          }}
                        </div>
                        <div
                          v-if="
                            receipt.receiptTotalAmount !==
                            receipt.receiptTotalOriginalAmount
                          "
                          class="header-tag"
                        >
                          {{
                            $t(
                              "TransactionDetailsPageReceiptHeaderTagPriceDiferenceLabel"
                            )
                          }}
                          <span class="u-font--bold u-font--medium">{{
                            (
                              receipt.receiptTotalAmount -
                              receipt.receiptTotalOriginalAmount
                            ).toLocaleString()
                          }}</span>
                          EGP
                          <span class="u-font--bold u-font--medium"
                            >({{
                              (((receipt.receiptTotalAmount -
                                receipt.receiptTotalOriginalAmount) /
                                receipt.receiptTotalOriginalAmount) *
                                100)
                                | fotmatFloatValues
                            }}%)</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="u-display-flex right-container">
                      <div
                        v-if="
                          receipt.receiptTotalAmount ||
                          receipt.receiptTotalOriginalAmount
                        "
                        :class="{
                          'header-tag--unvalid':
                            receipt.receiptDistributer.minOrder >
                              receipt.receiptTotalAmount ||
                            receipt.receiptDistributer.id == -1,
                          'header-tag--valid':
                            receipt.receiptDistributer.minOrder <=
                              receipt.receiptTotalAmount &&
                            receipt.receiptDistributer.id !== -1,
                        }"
                      >
                        <span class="u-font--bold u-font--medium">{{
                          (
                            receipt.receiptTotalAmountVisual ||
                            receipt.receiptTotalOriginalAmount
                          ).toLocaleString()
                        }}</span>
                        EGP
                      </div>
                    </div>
                  </div>
                </template>
                <div class="card-body">
                  <div class="c-fatura-card__header-buttons">
                    <el-tooltip
                      :content="$t('TransactionDetailsPrintToolTipMessage')"
                      :disabled="canPrint"
                      placement="top"
                      effect="dark"
                    >
                      <div>
                        <el-button
                          :disabled="isPrintLoading || !canPrint"
                          @click.stop="onPrintReceipt(receipt)"
                          class="u-right-margin--1x"
                          >{{
                            $t("TransactionDetailsPageReceiptPrintBtn")
                          }}</el-button
                        >
                      </div>
                    </el-tooltip>

                    <el-button
                      v-if="receipt.receiptStatus == 'inprogress'"
                      @click.stop="changeActualValue(receipt)"
                      class="u-right-margin--1x"
                      >{{
                        $t(
                          "TransactionDetailsPageReceiptSetActualValueAndCompleteBtn"
                        )
                      }}
                    </el-button>

                    <el-button
                      v-if="receipt.receiptStatus == 'pending'"
                      @click.stop="cancelRecipt(receipt)"
                      class="u-right-margin--1x"
                      >{{ $t("TransactionDetailsPageReceiptCancelBtn") }}
                    </el-button>
                    <el-dropdown
                      class="u-right-margin--1x steal-btn"
                      @command="stealAnItem"
                      trigger="click"
                    >
                      <el-button
                        plain
                        :disabled="!receipt.stealItems"
                        @click.stop="onOpenStealButton"
                      >
                        <div class="u-display-flex u-justify-content--between">
                          <div class="">
                            {{
                              $t(
                                "TransactionDetailsPageReceiptStealItemsDropdown"
                              )
                            }}
                          </div>
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </div>
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <div
                          v-for="(distributer, key) in receipt.stealItems"
                          :key="key"
                        >
                          <el-dropdown-item disabled class="u-right">{{
                            distributer[0].distributer.name
                          }}</el-dropdown-item>
                          <el-dropdown-item
                            v-for="(item, key) in distributer"
                            :key="key"
                            :command="{ stolenItem: item, receipt }"
                          >
                            <div
                              class="u-display-flex u-direction--ltr u-flex--row-reverse"
                            >
                              <div>{{ item.product.product.name }}</div>
                              <div class="u-color--red-light">
                                ({{ item.priceDifference }})
                              </div>
                            </div>
                          </el-dropdown-item>
                        </div>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                  <!--    <div>
                        <div
                          class="u-font--medium u-font--bold u-right-margin--1x u-display-flex"
                        >
                          <span class="u-left-margin--4x" v-if="receipt.notes">
                            <el-tooltip
                              class="item"
                              effect="dark"
                              :content="receipt.notes"
                              placement="top-start"
                            >
                              <i class="el-icon-s-comment"></i>
                            </el-tooltip>
                          </span>
                        </div>
                      </div> -->
                  <div>
                    {{ $t("TransactionDetailsPageReceiptReceiptScoresLabel") }}
                    <el-rate
                      disabled
                      v-model="receipt.review_score"
                      :allow-half="false"
                    ></el-rate>
                  </div>
                  <div>
                    <h1>
                      {{ $t("TransactionDetailsPageReceiptReviewNotesLabel") }}
                    </h1>
                    <el-input
                      class="u-bottom-margin--2x"
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      :placeholder="
                        $t(
                          'TransactionDetailsPageReceiptInputReviewNotesPlaceholder'
                        )
                      "
                      v-model="receipt.review_notes"
                      disabled
                    ></el-input>
                  </div>

                  <div>
                    <h1>
                      {{
                        $t("TransactionDetailsPageReceiptWholesalerNotesLabel")
                      }}
                    </h1>
                    <el-input
                      class="u-bottom-margin--2x"
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      :placeholder="
                        $t(
                          'TransactionDetailsPageReceiptInputWholeSalerNotesPlaceholder'
                        )
                      "
                      v-model="receipt.notes"
                    ></el-input>
                  </div>

                  <el-table
                    :data="receipt.items"
                    style="width: 100%"
                    row-class-name="no-hover"
                    cell-class-name="t-coulumn"
                    :default-sort="{
                      prop: 'priceDifference',
                      order: 'descending',
                    }"
                    header-cell-class-name="u-text--center"
                  >
                    <el-table-column
                      prop="product.name"
                      :label="
                        $t('TransactionDetailsPageReceiptDetailsColumnTitle')
                      "
                      width="300"
                    >
                      <template slot-scope="scope">
                        <div class="u-display-flex">
                          <div class="u-right-margin--2x">
                            <img
                              width="50"
                              height="50"
                              style="object-fit: scale-down"
                              :src="scope.row.product.image"
                            />
                          </div>
                          <div class="u-direction--rtl">
                            <div>
                              {{ scope.row.product.name }}
                            </div>
                            <div class="u-display-flex u-direction--ltr">
                              <div class="u-right-padding--half-x">
                                {{ scope.row.product.unit }}
                              </div>
                              <div class="u-direction--rtl">
                                {{ scope.row.product.itemsPerUnit }}
                              </div>
                            </div>
                            <div class="u-font--xsmall">
                              ID:
                              <strong>{{ scope.row.productId }}</strong>
                            </div>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="originalQuantity"
                      :label="
                        $t('TransactionDetailsPageReceiptQuantityColumnTitle')
                      "
                      width="80"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.quantity }}
                        <br />
                        <el-button
                          type="text"
                          @click="changeItemQuantity(scope.row)"
                          >{{
                            $t("TransactionDetailsPageReceiptQuantityColumnBtn")
                          }}</el-button
                        >
                      </template>
                    </el-table-column>
                    <el-table-column
                      :label="
                        $t('TransactionDetailsPageReceiptPriceBigColumnTitle')
                      "
                    >
                      <el-table-column
                        prop="price"
                        :label="
                          $t(
                            'TransactionDetailsPageReceiptProviderPriceColumnTitle'
                          )
                        "
                        width="80"
                      >
                        <template slot-scope="scope">
                          {{ scope.row.price }}
                          <br />
                          <el-button
                            type="text"
                            @click="changeItemPrice(scope.row)"
                            >{{
                              $t(
                                "TransactionDetailsPageReceiptProviderPriceColumnBtn"
                              )
                            }}</el-button
                          >
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="priceDifference"
                        :label="
                          $t(
                            'TransactionDetailsPageReceiptPriceDiferenceColumnTitle'
                          )
                        "
                        width="80"
                      >
                        <template slot-scope="scope">
                          <div
                            v-if="scope.row.priceDifference"
                            class="u-color--red-light"
                          >
                            {{ scope.row.priceDifference }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="cashPrice"
                        v-if="transactionDetails.method == 'credit'"
                        :label="
                          $t(
                            'TransactionDetailsPageReceiptCashPriceColumnTitle'
                          )
                        "
                        width="80"
                      >
                        <template slot-scope="scope">
                          {{ scope.row.originalPrice }}
                          <br />
                          <el-button
                            type="text"
                            @click="changeItemOriginalPrice(scope.row)"
                            >{{
                              $t(
                                "TransactionDetailsPageReceiptCashPriceColumnBtn"
                              )
                            }}</el-button
                          >
                        </template>
                      </el-table-column>

                      <el-table-column
                        prop="CreditPrice"
                        v-if="transactionDetails.method == 'credit'"
                        :label="
                          $t(
                            'TransactionDetailsPageReceiptAgelPriceColumnTitle'
                          )
                        "
                        width="80"
                      >
                        <template slot-scope="scope">
                          {{ bundlePrice(scope.row.originalPrice) }}
                          <br />
                          <el-button
                            type="text"
                            @click="changeItemOriginalPrice(scope.row)"
                            >{{
                              $t(
                                "TransactionDetailsPageReceiptAgelPriceColumnBtn"
                              )
                            }}</el-button
                          >
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="originalPrice"
                        :label="
                          $t(
                            'TransactionDetailsPageReceiptFaturaPriceColumnTitle'
                          )
                        "
                        width="80"
                      >
                        <template slot-scope="scope">
                          {{ faturaPrice(scope.row.originalPrice) }}
                          <br />
                          <el-button
                            type="text"
                            @click="changeItemOriginalPrice(scope.row)"
                            >{{
                              $t(
                                "TransactionDetailsPageReceiptFaturaPriceColumnBtn"
                              )
                            }}</el-button
                          >
                        </template>
                      </el-table-column>
                    </el-table-column>
                    <el-table-column label>
                      <template slot-scope="scope">
                        <div>
                          <el-dropdown
                            :class="
                              $store.state.isRTL
                                ? 'u-left-padding--1x'
                                : 'u-right-padding--1x'
                            "
                            @command="onChangeItemDitributerOrCancel"
                          >
                            <el-button plain>
                              <i class="el-icon-refresh"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item
                                v-for="(provider, key) in scope.row
                                  .possibleDistributers"
                                :key="key"
                                :disabled="
                                  provider.id == scope.row.distributerId ||
                                  selectedDistributers.indexOf(
                                    provider.id.toString()
                                  ) < 0
                                "
                                :command="{
                                  currentItem: scope.row,
                                  selectedDistributer: provider,
                                }"
                                >{{ provider.name }} -
                                {{ provider.price }}</el-dropdown-item
                              >
                            </el-dropdown-menu>
                          </el-dropdown>

                          <el-button
                            class="u-top-margin--1x"
                            type="danger"
                            plain
                            icon="el-icon-delete"
                            v-if="scope.row.distributerId !== -1"
                            @click="
                              onChangeItemDitributerOrCancel({
                                currentItem: scope.row,
                                selectedDistributer: {
                                  id: -1,
                                  price: scope.row.originalPrice,
                                  name: 'Canceled Items',
                                },
                              })
                            "
                          >
                          </el-button>

                          <span
                            v-if="
                              scope.row.isFaturaProduct &&
                              scope.row.isFaturaProduct == 1
                            "
                            :class="
                              $store.state.isRTL
                                ? 'u-right-padding--1x'
                                : 'u-left-padding--1x'
                            "
                          >
                            {{
                              $t(
                                "TransactionDetailsPageReceiptFaturaProductLabel"
                              )
                            }}
                            <emoji
                              :class="
                                $store.state.isRTL
                                  ? 'u-right-padding--1x'
                                  : 'u-left-padding--1x'
                              "
                              :emoji="{ id: 'star', skin: 1 }"
                              :size="15"
                            />
                          </span>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6" :offset="0">
        <el-card
          :body-style="{ padding: '0px' }"
          v-if="transactionDetails.user"
        >
          <div class="transaction-info">
            <div class="t-number u-bg--normal-text-color">
              {{ transactionDetails.orderId }}
            </div>
            <div class="t-date u-font--small">
              {{ transactionDetails.createdAt | moment("DD MMM YYYY  h:mm a") }}
            </div>
          </div>
          <div class="user-info">
            <div
              class="user-avatar-name u-top-margin--2x u-color--normal-text-color"
            >
              <div
                :class="`u-avatar u-font--xsuper  ${
                  $store.state.isRTL
                    ? 'u-left-margin--2x'
                    : 'u-right-margin--2x'
                }`"
              >
                <i class="el-icon-user"></i>
              </div>
              <div class="u-sname-id">
                <div class="u-font--bold">
                  {{ transactionDetails.user.storeName }}
                </div>
                <div class="u-color--small-text-color u-font--small">
                  # {{ transactionDetails.user.id }}
                </div>
              </div>
            </div>
            <div
              class="u-name-phone u-top-margin--2x u-color--normal-text-color"
            >
              <div class="u-font--bold">{{ transactionDetails.user.name }}</div>
              <div>{{ transactionDetails.user.phone }}</div>
              <div>{{ transactionDetails.user.landline }}</div>
              <div>{{ transactionDetails.user.secondPhone }}</div>
            </div>
            <div class="spacer"></div>

            <div class="user-area">
              {{ transactionDetails.user.area.name }}
              <br />
              {{ transactionDetails.user.city }}
              <br />
              {{ transactionDetails.user.address }}
            </div>
            <div class="spacer"></div>
            <div
              class="user-avatar-name u-top-margin--2x u-color--normal-text-color"
            >
              <div
                :class="`u-avatar u-font--super  ${
                  $store.state.isRTL
                    ? 'u-left-margin--2x'
                    : 'u-right-margin--2x'
                }`"
              >
                <i class="el-icon-coin"></i>
              </div>
              <div class="u-sname-id">
                <div class="u-font--bold">
                  {{ $t("TransactionDetailsPageInfoPaymentMethodLabel") }}
                </div>
                <div class="u-color--small-text-color u-font--small">
                  {{ transactionDetails.method }}
                </div>
              </div>
            </div>
            <div class="spacer"></div>
            <div
              class="user-avatar-name u-top-margin--2x u-color--normal-text-color"
            >
              <div
                :class="`u-avatar u-font--super  ${
                  $store.state.isRTL
                    ? 'u-left-margin--2x'
                    : 'u-right-margin--2x'
                }`"
              >
                <i class="el-icon-s-flag"></i>
              </div>
              <div class="u-sname-id">
                <div class="u-font--bold">
                  {{ $t("TransactionDetailsPageInfoLandmarkLabel") }}
                </div>
                <div class="u-color--small-text-color u-font--small">
                  {{
                    transactionDetails.user.addressLandmark
                      ? transactionDetails.user.addressLandmark
                      : this.$t("TransactionDetailsPageLandmarkNoDataLabel")
                  }}
                </div>
              </div>
            </div>
            <div class="spacer"></div>
            <div
              class="user-avatar-name u-top-margin--2x u-color--normal-text-color"
            >
              <div
                :class="`u-avatar u-font--super  ${
                  $store.state.isRTL
                    ? 'u-left-margin--2x'
                    : 'u-right-margin--2x'
                }`"
              >
                <i class="el-icon-shopping-cart-2"></i>
              </div>
              <div class="u-sname-id">
                <div class="u-font--bold">
                  {{ transactionDetails.section.selling_type }}
                </div>
                <div class="u-color--small-text-color u-font--small">
                  {{ transactionDetails.section.title }}
                </div>
              </div>
            </div>
            <div class="spacer"></div>
            <template v-if="transactionDetails.user.notes">
              <div
                class="user-avatar-name u-top-margin--2x u-color--normal-text-color"
              >
                <div
                  :class="`u-avatar u-font--super  ${
                    $store.state.isRTL
                      ? 'u-left-margin--2x'
                      : 'u-right-margin--2x'
                  }`"
                >
                  <i class="el-icon-s-order"></i>
                </div>
                <div class="u-sname-id">
                  <div class="u-font--small">
                    {{ transactionDetails.user.notes }}
                  </div>
                </div>
              </div>
              <div class="spacer"></div>
            </template>
            <template v-if="transactionDetails.notes">
              <div
                class="user-avatar-name u-top-margin--2x u-color--normal-text-color"
              >
                <div
                  :class="`u-avatar u-font--super  ${
                    $store.state.isRTL
                      ? 'u-left-margin--2x'
                      : 'u-right-margin--2x'
                  }`"
                >
                  <i class="el-icon-s-order"></i>
                </div>
                <div class="u-sname-id">
                  <div class="u-font--small">
                    {{ transactionDetails.notes }}
                  </div>
                </div>
              </div>
              <div class="spacer"></div>
            </template>
            <div class="u-top-margin--2x">
              <!-- <div class="u-avatar u-font--super u-right-margin--2x"> -->
              <!-- <i class="el-icon-s-order"></i> -->
              <!-- </div> -->
              <div class="u-sname-id">
                <div class="u-font--bold u-color--normal-text-color">
                  {{ $t("TransactionDetailsPageInfoInternalNoteLabel") }}
                </div>
                <div>
                  <text-area-action
                    style="margin-top: 1rem"
                    v-model="transactionDetails.user.internalNote"
                    @check-click="saveInternalNote"
                  ></text-area-action>
                </div>
              </div>
            </div>
            <div class="spacer"></div>
          </div>
          <div class="total-cash u-color--normal-text-color">
            <div class="u-font--xsmall">
              {{ $t("TransactionDetailsPageInfoTotalCashLabel") }}
            </div>
            <div class="u-font--xsmall">
              <span class="u-font--large u-font--bold">{{
                transactionDetails.totalAmount
              }}</span
              >&nbsp; EGP
            </div>
          </div>
        </el-card>
        <el-card
          v-if="
            transactionDetails.state != 'complete' &&
            transactionDetails.state != 'unfulfilled'
          "
          class="u-top-margin--2x"
          :body-style="{ padding: '13px' }"
        >
          <div>
            <el-button
              type="primary"
              slot="reference"
              class="u-font--medium card-btn save-btn"
              @click="saveOrderReceipts()"
              >{{ $t("TransactionDetailsPageInfoSaveReceiptsBtn") }}</el-button
            >
          </div>
          <div>
            <el-button
              class="u-font--medium card-btn progress-btn"
              v-if="showChangeStatus && nextStatus"
              @click="changeTransactionStatus()"
              >{{ $t("TransactionDetailsPageInfoChangeStatusBtn") }}
              {{ nextStatus | capitalize }}</el-button
            >
          </div>
        </el-card>
      </el-col>
    </el-row>
    <TransactionDetailsAutoAssignmentCancellationDialog
      v-model="autoAssignmentDialogFlag"
      :transactionId="transactionId"
      @autoAssignmentStopped="autoAssignmentStopped"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { transactions, manualOrderHandling, adminActions } from "@/services";
import { Emoji } from "emoji-mart-vue";
import TextAreaAction from "../components/dataContainers/TextAreaAction.vue";
import TransactionDetailsAutoAssignmentCancellationDialog from "./TransactionScreens/TransactionsManagement/TransactionDetailsAutoAssignmentCancellationDialog.vue";
export default {
  name: "DistributerDetails",
  components: {
    Emoji,
    TextAreaAction,
    TransactionDetailsAutoAssignmentCancellationDialog,
  },
  data() {
    return {
      productDistirubtersPricesMap: {},
      canPrint: true,
      enablePrintTimer: {},
      receiptsSaved: false,
      transactionDetails: {},
      selectedDistributers: [],
      orderDistributers: {},
      receipts: [],
      originalReceipts: [],
      cancelledOrderProps: {},
      creditConfig: {},
      transactionReceiptsSaveInprogress: false,
      isPrintLoading: false,
      autoAssignmentDialogFlag: false,
    };
  },
  computed: {
    transactionId() {
      return this.$route.params.id;
    },
    showChangeStatus() {
      if (
        _.includes(["in progress", "processed"], this.transactionDetails.state)
      ) {
        return true;
      }
      return false;
    },
    nextStatus() {
      //keep the old logic for future states
      if (_.includes(["neverShow"], this.transactionDetails.state)) {
        return "in progress";
      } else if (this.transactionDetails.state) {
        return "complete";
      } else {
        return false;
      }
    },
  },
  filters: {
    fotmatFloatValues: function (value) {
      if (!value) return "";
      let filteredValue = value.toFixed(1);
      let output = 0;
      if (parseFloat(filteredValue) > 0) {
        output = filteredValue;
      }
      return output.toString();
    },
    capitalize: (val) => {
      return _.capitalize(val);
    },
  },
  created() {
    // const pageLoading = this.$loading();
    const creditConfig = adminActions.getConfig("credit");
    const transactionDetails = transactions.getTransactionDetails(
      this.transactionId
    );
    const transactionDistributers = transactions.getTransactionDistributers(
      this.transactionId
    );
    Promise.all([creditConfig, transactionDetails, transactionDistributers])
      .then((results) => {
        this.creditConfig = results[0].data.config;
        //setup originalPrice,originalQuantity,priceDifference in transactionItems, calculate totalOriginalAmount
        this.transactionDetails = this.initTransactionsObject(
          results[1].transaction
        );

        this.orderDistributers = manualOrderHandling.flattenOrderDistributers(
          results[2].transactionDistributes
        );

        _.forEach(this.orderDistributers, (i) => {
          i.products.forEach((p) => {
            if (!this.productDistirubtersPricesMap[p.productId]) {
              this.productDistirubtersPricesMap[p.productId] = {};
            }
            this.productDistirubtersPricesMap[p.productId][p.distributerId] =
              p.cashPrice;
          });
        });
        if (this.transactionDetails.receipts.length) {
          this.receiptsSaved = true;
          // change trxItems price,quantity,priceDifference,distributerId,provider according to receipts
          // if provider is null then set it as Canceld Order
          this.transactionDetails.receipts = this._processSavedOrderReceipts(
            this.transactionDetails.receipts
          );
          this.originalReceipts = _.cloneDeep(
            // groups by distributerId, sets this.selectedDistributers, calculates receiptTotalAmount, steal items logic, formulate receipts
            this.calculateOrderReceipts(this.transactionDetails.items)
          );
        }
        // // sets item.possibleDistributers
        this.transactionDetails.items = this._processItemPossibleDistributers(
          this.transactionDetails.items
        );
        if (
          this.transactionDetails.section.selling_type == "aggregate" &&
          this.transactionDetails.state == "pending" &&
          this.transactionDetails.receipts.length == 0
        )
          // sets trxItems price,priceDifference,distributerId,provider
          this.selectBestDistibuters();
        // sets this.receipts with calculateOrderReceipts(this.transactionDetails.items), gets cancelledReceipt,
        // calculate totalAmount,totalOriginalAmount,creditTotalAmount
        // sets cancelledOrderProps
        this.updateReceipts();
      })
      .finally(() => {
        // pageLoading.close();
      });
  },
  methods: {
    // sets trxItems price,priceDifference,distributerId,provider
    selectBestDistibuters() {
      let items = this.transactionDetails.items;
      for (let i = 0; i < items.length; i++) {
        const product = items[i];
        let bestPriceDistributer = this.getbestPriceFromDistibuters(product);
        product.price = bestPriceDistributer.price;
        product.priceDifference = (
          product.price - product.originalPrice
        ).toFixed(2);
        if (product.priceDifference < 0) {
          product.originalPrice = product.price;
          product.priceDifference = 0;
        }
        product.distributerId = bestPriceDistributer.distributerId;
        product.provider.id = bestPriceDistributer.distributerId;
        product.provider.name = bestPriceDistributer.name;
      }
    },
    getbestPriceFromDistibuters(item) {
      //intial item price

      let bestPriceItem = {
        price: this.productDistirubtersPricesMap[item.productId][
          Object.keys(this.productDistirubtersPricesMap[item.productId])[0]
        ],
        distributerId: Object.keys(
          this.productDistirubtersPricesMap[item.productId]
        )[0],
        name: this.orderDistributers[
          Object.keys(this.productDistirubtersPricesMap[item.productId])[0]
        ].name,
      };
      //get best Price item
      _.forOwn(this.orderDistributers, (value, key) => {
        const price = this.productDistirubtersPricesMap[item.productId][key];
        if (price && price < bestPriceItem.price)
          bestPriceItem = {
            price: price,
            distributerId: key,
            name: value.name,
          };
      });
      return bestPriceItem;
    },
    canDistibuterBeShowen(distributer) {
      let showen = true;
      if (this.transactionDetails.section == "direct") {
        showen =
          (distributer.original || distributer.receipt) &&
          !distributer.blacklist;
      }
      if (this.transactionDetails.state != "pending") {
        showen = showen && distributer.receipt;
      }

      return showen;
    },
    cancelRecipt(recipt) {
      this.$confirm(
        this.$t("TransactionDetailsCancelReceiptMessageBoxMessage"),
        this.$t("TransactionDetailsCancelReceiptMessageBoxTitle"),
        {
          confirmButtonText: this.$t(
            "TransactionDetailsCancelReceiptMessageBoxOkBtn"
          ),
          cancelButtonText: this.$t(
            "TransactionDetailsCancelReceiptMessageBoxCancelBtn"
          ),
        }
      )
        .then(async () => {
          // this.$loading();

          _.forEach(recipt.items, (item) => {
            this.onChangeItemDitributerOrCancel({
              currentItem: item,
              selectedDistributer: {
                id: -1,
                price: item.originalPrice,
                name: "Canceled Items",
              },
            });
          });
          await this.saveOrderReceipts();
        })

        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("TransactionDetailsErrorMessage"),
          });
        });
    },
    // change trxItems price,quantity,priceDifference,distributerId,provider according to receipts
    _processSavedOrderReceipts(orderReceipts) {
      _.each(orderReceipts, (receipt) => {
        let currentReceiptDistributer = receipt.provider;
        if (!currentReceiptDistributer) {
          currentReceiptDistributer = {
            id: -1,
            name: "Canceled Order",
          };
        }

        _.each(receipt.items, (receiptItem) => {
          let originalTransactionItem = _.find(this.transactionDetails.items, {
            // distributerId: currentReceiptDistributer.id,
            productId: receiptItem.productId,
          });
          if (currentReceiptDistributer.id !== -1) {
            originalTransactionItem.price = receiptItem.price;
            originalTransactionItem.quantity = receiptItem.quantity;
          }
          originalTransactionItem.priceDifference = (
            originalTransactionItem.price -
            originalTransactionItem.originalPrice
          ).toFixed(2);
          originalTransactionItem.distributerId = currentReceiptDistributer.id;
          originalTransactionItem.provider.id = currentReceiptDistributer.id;
          originalTransactionItem.provider.name =
            currentReceiptDistributer.name;
        });
      });
      return orderReceipts;
    },
    // sets item.possibleDistributers
    _processItemPossibleDistributers(items) {
      _.forEach(items, (item) => {
        item.possibleDistributers = _.map(
          this.productDistirubtersPricesMap[item.productId],
          (distributerPrice, distributerId) => {
            return {
              id: parseInt(distributerId),
              price: distributerPrice,
              name: this.orderDistributers[distributerId].name,
            };
          }
        );
      });
      return items;
    },
    goBack() {
      this.$router.go(-1);
    },
    handleDistributersChange(isDistributerSelected, { target: { value } }) {
      // removeDistributerFromOrder
      let removedDistributer = _.first(
        _.filter(this.receipts, (o) => {
          return o.receiptDistributer.id.toString() === value;
        })
      );
      if (!isDistributerSelected && removedDistributer) {
        // if (!removedDistributer) {
        //   this.$alert(
        //     "Can't find any product that can be assigned to the selected distributer",
        //     "Distributer has no suitable items",
        //     {
        //       confirmButtonText: "OK",
        //     }
        //   );
        // }
        // if (removedDistributer) {
        _.forEach(removedDistributer.items, (item) => {
          let bestPriceItem = [];
          // get prices of items only from current order distributers
          _.each(this.productDistirubtersPricesMap[item.productId], (o, i) => {
            if (this.selectedDistributers.indexOf(i) > -1) {
              bestPriceItem.push({
                distributerId: i,
                cashPrice: o,
                productId: item.productId,
              });
            }
          });
          // get lowest price of remaining order distributers
          bestPriceItem =
            _.first(_.orderBy(bestPriceItem, ["cashPrice"], ["asc"])) || false;
          if (bestPriceItem) {
            item.price = bestPriceItem.cashPrice;
            item.priceDifference = (item.price - item.originalPrice).toFixed(2);
            if (item.priceDifference < 0) {
              item.originalPrice = item.price;
              item.priceDifference = 0;
            }
            item.distributerId = parseInt(bestPriceItem.distributerId);
            item.provider.id = this.orderDistributers[
              bestPriceItem.distributerId
            ].id;
            item.provider.name = this.orderDistributers[
              bestPriceItem.distributerId
            ].name;
          } else {
            item.price = item.originalPrice; // ignore any modifications done before move to cancel
            item.priceDifference = 0;
            item.distributerId = -1;
            item.provider.id = -1;
            item.provider.name = "Canceled Items";
          }
        });
        // }
      } else {
        // addDistributerToOrder
        let addedDistributer = _.first(
          _.filter(this.orderDistributers, (o) => {
            return o.id.toString() === value;
          })
        );
        let distributerAssignToAnItem = false;
        let distributerhasNoProductTobeassgiend = true;
        // reassign all products with best prices from original order to new distributer
        // reassign possible canceled items to selected distributer
        _.forEach(this.transactionDetails.items, (item) => {
          let distributerProductPrice =
            addedDistributer.productsPriceMap[item.productId];
          if (distributerProductPrice)
            distributerhasNoProductTobeassgiend = false;
          if (
            (distributerProductPrice && item.price > distributerProductPrice) || // reassign to best price
            (item.distributerId === -1 && distributerProductPrice) // reassign posibble canceled items
          ) {
            if (!distributerAssignToAnItem) distributerAssignToAnItem = true;

            item.price = distributerProductPrice;
            item.priceDifference = (item.price - item.originalPrice).toFixed(2);
            if (item.priceDifference < 0) {
              item.originalPrice = item.price;
              item.priceDifference = 0;
            }
            item.distributerId = addedDistributer.id;
            item.provider.id = addedDistributer.id;
            item.provider.name = addedDistributer.name;
          }
        });

        if (!distributerAssignToAnItem && distributerhasNoProductTobeassgiend) {
          this.$alert(
            this.$t("TransactionDetailsNoSutibleProductErrorMessage"),
            this.$t("TransactionDetailsNoSutibleProductErrorTitle"),
            {
              confirmButtonText: this.$t(
                "TransactionDetailsNoSutibleProductErrorOkBtn"
              ),
            }
          );
        } else if (!distributerAssignToAnItem) {
          this.$alert(
            this.$t("TransactionDetailsNotBestPriceProviderErrorMessage"),
            this.$t("TransactionDetailsNotBestPriceProviderErrorTitle"),

            {
              confirmButtonText: this.$t(
                "TransactionDetailsNotBestPriceProviderErrorOkBtn"
              ),
            }
          );
        }
      }
      this.updateReceipts();
    },
    bundlePrice(price) {
      let faturaPrice = 0;

      if (!this.transactionDetails.bundle) {
        faturaPrice =
          price *
          (this.transactionDetails.method == "credit"
            ? Number.parseFloat(this.creditConfig.Credit_Premium)
            : 1);
      } else {
        faturaPrice =
          price *
          (this.transactionDetails.method == "credit"
            ? Number.parseFloat(
                this.transactionDetails.bundle.premiumPercentage
              )
            : 1);
      }
      faturaPrice = (Math.round(faturaPrice * 4) / 4).toFixed(2);

      return faturaPrice;
    },
    faturaPrice(price) {
      let faturaPrice = 0;
      //check if it transaction has a bundle or not
      if (!this.transactionDetails.bundle) {
        faturaPrice =
          price *
          (this.transactionDetails.method == "credit"
            ? 1 + Number.parseFloat(this.creditConfig.Credit_Premium)
            : 1);
      } else {
        faturaPrice =
          price *
          (this.transactionDetails.method == "credit"
            ? 1 +
              Number.parseFloat(
                this.transactionDetails.bundle.premiumPercentage
              )
            : 1);
      }
      faturaPrice = (Math.round(faturaPrice * 4) / 4).toFixed(2);
      return faturaPrice;
    },
    //setup originalPrice,originalQuantity,priceDifference in transactionItems, calculate totalOriginalAmount
    initTransactionsObject(transaction) {
      _.map(transaction.items, (item) => {
        item.originalPrice = item.price;
        item.originalQuantity = item.quantity;
        item.priceDifference = item.price - item.originalPrice;
      });
      let transactionTotalAmount, transactionCreditTotalAmount;
      if (transaction.state == "pending") {
        transactionTotalAmount = _.reduce(
          transaction.items,
          (sum, item) => {
            return sum + item.originalPrice * item.quantity;
          },
          0
        );
        transactionCreditTotalAmount = _.reduce(
          transaction.items,
          (sum, item) => {
            return sum + this.faturaPrice(item.originalPrice) * item.quantity;
          },
          0
        );
      } else {
        transactionTotalAmount = _.reduce(
          transaction.receipts,
          (sum, receipt) => {
            let totalReceiptItemPrice = _.reduce(
              receipt.items,
              (receiptTotal, item) => {
                return receiptTotal + item.price * item.quantity;
              },
              0
            );
            return sum + totalReceiptItemPrice;
          },
          0
        );
        transactionCreditTotalAmount = _.reduce(
          transaction.receipts,
          (sum, receipt) => {
            let totalReceiptItemPrice = _.reduce(
              receipt.items,
              (receiptTotal, item) => {
                return (
                  receiptTotal + this.faturaPrice(item.price) * item.quantity
                );
              },
              0
            );
            return sum + totalReceiptItemPrice;
          },
          0
        );
      }
      transaction.totalOriginalAmount = transactionTotalAmount;
      transaction.creditTotalAmount = transactionCreditTotalAmount;
      return transaction;
    },
    // groups by distributerId, sets this.selectedDistributers, calculates receiptTotalAmount, steal items logic, formulate receipts
    calculateOrderReceipts(orderReceipts) {
      let tempReceipts = _.groupBy(orderReceipts, "distributerId");
      let finalReceipts = [];
      this.selectedDistributers = Object.keys(tempReceipts);

      Object.values(tempReceipts).forEach((element) => {
        // calculate order total amount
        let receiptTotalAmount = _.reduce(
          element,
          (sum, n) => {
            return sum + n.price * n.quantity;
          },
          0
        );
        let receiptTotalAmountVisual = _.reduce(
          element,
          (sum, n) => {
            return sum + this.faturaPrice(n.price) * n.quantity;
          },
          0
        );
        let receiptTotalOriginalAmount = _.reduce(
          element,
          (sum, n) => {
            return sum + n.originalPrice * n.quantity;
          },
          0
        );
        const receiptDistributer = _.first(element).provider;
        if (this.orderDistributers[receiptDistributer.id]) {
          receiptDistributer.minOrder = this.orderDistributers[
            receiptDistributer.id
          ].minOrder;
        } else {
          receiptDistributer.minOrder = 0;
        }
        let currentDistributerAvailableItemsIds = [];
        if (receiptDistributer.id !== -1) {
          currentDistributerAvailableItemsIds = Object.keys(
            this.orderDistributers[receiptDistributer.id].productsPriceMap
          );
        }
        // receiptDistributer availableItems ids, then items possible distributers
        let final = {};
        _.each(currentDistributerAvailableItemsIds, (productId) => {
          final[productId] = this.productDistirubtersPricesMap[productId];
        });
        let stealItems = {};
        _.each(final, (possibleDistributers, productId) => {
          _.each(possibleDistributers, (price, distributerId) => {
            let product = _.find(this.transactionDetails.items, {
              productId: parseInt(productId),
            });
            if (
              this.selectedDistributers.includes(distributerId) &&
              distributerId != receiptDistributer.id &&
              product.distributerId == distributerId
            ) {
              if (!stealItems[distributerId]) stealItems[distributerId] = [];
              let stolenProduct = _.find(
                this.orderDistributers[receiptDistributer.id].products,
                { productId: parseInt(productId) }
              );
              stealItems[distributerId].push({
                productId,
                distributerId: distributerId,
                price: stolenProduct.cashPrice,
                product,
                distributer: this.orderDistributers[distributerId],
                priceDifference: stolenProduct.cashPrice - product.price,
              });
            }
          });
        });
        let receiptObject = {
          items: element,
          receiptDistributer,
          receiptTotalAmount,
          receiptTotalAmountVisual,
          receiptTotalOriginalAmount,
          stealItems: _.isEmpty(stealItems) ? false : stealItems,
        };
        // get equivalent receipt from transaction.receipts
        let extraReceiptDetails = _.find(
          this.transactionDetails.receipts,
          (singleReceipt) => {
            let rDistributer = -1;
            if (singleReceipt.provider) {
              rDistributer = singleReceipt.provider.id;
            }
            return rDistributer === receiptDistributer.id;
          }
        );
        if (extraReceiptDetails) {
          receiptObject["recepitId"] = extraReceiptDetails.id;
          receiptObject["receiptStatus"] = extraReceiptDetails.status;
          receiptObject["updatedAt"] = extraReceiptDetails.updatedAt;
          receiptObject["notes"] = extraReceiptDetails.notes;
          receiptObject["undeliver_reason"] =
            extraReceiptDetails.undeliver_reason;
          receiptObject["review_notes"] = extraReceiptDetails.review_notes;
          receiptObject["review_score"] = extraReceiptDetails.review_score;
          receiptObject["actualAmount"] = extraReceiptDetails.actualAmount;
        }
        finalReceipts.push(receiptObject);
      });
      return finalReceipts;
    },
    onOpenStealButton(e) {
      e.stopPropagation();
    },
    // sets this.receipts with calculateOrderReceipts(this.transactionDetails.items), gets cancelledReceipt,
    // calculate totalAmount,totalOriginalAmount,creditTotalAmount
    // sets cancelledOrderProps
    updateReceipts() {
      this.receipts = this.calculateOrderReceipts(
        this.transactionDetails.items
      );
      let cancelledOrderItems = _.first(
        _.filter(this.receipts, (r) => {
          return r.receiptDistributer.id === -1;
        })
      );
      // update current order total amount (we already store original amount to do our calculations),

      let transactionTotalAmount, transactionCreditTotalAmount;
      if (this.transactionDetails.state == "pending") {
        transactionTotalAmount = _.reduce(
          this.transactionDetails.items,
          (sum, item) => {
            return sum + item.originalPrice * item.quantity;
          },
          0
        );
        transactionCreditTotalAmount = _.reduce(
          this.transactionDetails.items,
          (sum, item) => {
            return sum + this.faturaPrice(item.originalPrice) * item.quantity;
          },
          0
        );
      } else {
        transactionTotalAmount = _.reduce(
          this.transactionDetails.receipts,
          (sum, receipt) => {
            let totalReceiptItemPrice = _.reduce(
              receipt.items,
              (receiptTotal, item) => {
                return receiptTotal + item.price * item.quantity;
              },
              0
            );
            return sum + totalReceiptItemPrice;
          },
          0
        );
        transactionCreditTotalAmount = _.reduce(
          this.transactionDetails.receipts,
          (sum, receipt) => {
            let totalReceiptItemPrice = _.reduce(
              receipt.items,
              (receiptTotal, item) => {
                return (
                  receiptTotal + this.faturaPrice(item.price) * item.quantity
                );
              },
              0
            );
            return sum + totalReceiptItemPrice;
          },
          0
        );
      }

      this.transactionDetails.totalAmount = transactionTotalAmount;
      this.transactionDetails.totalOriginalAmount = transactionTotalAmount;
      this.transactionDetails.creditTotalAmount = transactionCreditTotalAmount;

      this.transactionDetails.orderNetAmount = transactionTotalAmount;
      if (cancelledOrderItems && cancelledOrderItems.items.length) {
        this.transactionDetails.orderNetAmount =
          transactionTotalAmount - cancelledOrderItems.receiptTotalAmount;
        this.cancelledOrderProps = {
          amount: cancelledOrderItems.receiptTotalAmount,
          amountPercentge:
            (cancelledOrderItems.receiptTotalAmount /
              this.transactionDetails.totalAmount) *
            100,
          cancelledItemsCount: cancelledOrderItems.items.length,
          cancelledItemsCountPercentage:
            (cancelledOrderItems.items.length /
              this.transactionDetails.items.length) *
            100,
        };
      } else {
        this.cancelledOrderProps = {};
      }
    },
    onChangeItemDitributerOrCancel(options) {
      options.currentItem.price = options.selectedDistributer.price;
      options.currentItem.priceDifference =
        options.currentItem.price - options.currentItem.originalPrice;
      if (options.currentItem.priceDifference < 0) {
        options.currentItem.originalPrice = options.currentItem.price;
        options.currentItem.priceDifference =
          options.currentItem.price - options.currentItem.originalPrice;
      }
      options.currentItem.distributerId = options.selectedDistributer.id;
      options.currentItem.provider.id = options.selectedDistributer.id;
      options.currentItem.provider.name = options.selectedDistributer.name;
      this.updateReceipts();
    },

    async getTransactionDetails() {
      await transactions
        .getTransactionDetails(this.transactionId)
        .then((res) => {
          // update current order with new receipts
          this.transactionDetails = this.initTransactionsObject(
            res.transaction
          );
          if (this.transactionDetails.receipts.length) {
            this.receiptsSaved = true;
            this.transactionDetails.receipts = this._processSavedOrderReceipts(
              this.transactionDetails.receipts
            );
            this.originalReceipts = _.cloneDeep(
              this.calculateOrderReceipts(this.transactionDetails.items)
            );
          }
          this.transactionDetails.items = this._processItemPossibleDistributers(
            this.transactionDetails.items
          );
          transactions
            .getTransactionDistributers(this.transactionId)
            .then((res) => {
              this.orderDistributers = manualOrderHandling.flattenOrderDistributers(
                res.transactionDistributes
              );
            });
          this.updateReceipts();
          this.$notify({
            type: "success",
            title: this.$t("TransactionDetailsSuccessNotificationTitle"),
            message: this.$t("TransactionDetailsSuccessNotificationMessage"),
          });
        });
    },
    async saveOrderReceipts() {
      this.canPrint = false;
      clearTimeout(this.enablePrintTimer);
      this.enablePrintTimer = setTimeout(() => {
        this.canPrint = true;
      }, 30000);
      let receipts = [];
      this.transactionReceiptsSaveInprogress = true;
      // const pageLoading = this.$loading();
      if (this.receipts.length) {
        this.receipts.forEach((receipt) => {
          let receiptItem = {
            distributerId: receipt.receiptDistributer.id,
            discountAmount:
              receipt.receiptTotalAmount - receipt.receiptTotalOriginalAmount,
            id: receipt.recepitId,
            notes: receipt.notes,
            review_notes: receipt.review_notes,
            items: receipt.items.map(
              ({ productId, price, quantity, originalPrice }) => {
                return {
                  productId,
                  price,
                  quantity,
                  discountAmount: price - originalPrice,
                };
              }
            ),
          };

          receiptItem.isUpdated = this.checkReceiptUpdated(receipt);
          receipts.push(receiptItem);
        });
      }
      // if (receipts.length == 1 && receipts[0].distributerId == -1) {
      //   this.$loading();

      //   transactions
      //     .changeTransactionStatus(this.transactionId, this.nextStatus)
      //     .then(() => {
      //       this.transactionDetails.state = this.nextStatus;
      //       this.$message.success("Transaction Status updated");
      //     })
      //     .catch(() => {
      //       this.$message.error("Transaction Status failed");
      //     })
      //     .finally(() => {
      //       this.$loading().close();
      //     });
      // }
      await transactions
        .saveTransactionReceipts(this.transactionId, receipts)
        .then(
          async () => {
            await this.getTransactionDetails();
          },
          () => {
            this.$notify({
              type: "error",
              title: this.$t("TransactionDetailsErrorNotificationTitle"),
              message: this.$t("TransactionDetailsErrorNotificationMessage"),
              duration: 5000,
            });
          }
        )
        .finally(() => {
          this.transactionReceiptsSaveInprogress = false;
          // pageLoading.close();
        });
    },
    checkReceiptUpdated(receipt) {
      if (this.originalReceipts.length > 0) {
        // get the orginal receipt before any update
        const originalReceipt = this.originalReceipts.filter(
          (r) => r.recepitId == receipt.recepitId
        )[0];

        let isUpdated = false;

        if (
          originalReceipt &&
          originalReceipt.items.length == receipt.items.length
        )
          for (let i = 0; i < originalReceipt.items.length; i++) {
            const orginalRItem = originalReceipt.items[i];
            let updatedReceiptItem = _.cloneDeep(
              receipt.items.find((item) => item.id == orginalRItem.id)
            );

            //remove uncessry data to make comparison easier
            if (updatedReceiptItem) {
              orginalRItem.possibleDistributers = undefined;
              updatedReceiptItem.possibleDistributers = undefined;
            }
            //Comparing current receipt item with the orginal receipt item OR if item existed
            if (
              !updatedReceiptItem ||
              !_.isEqual(updatedReceiptItem, orginalRItem)
            ) {
              isUpdated = true;
              break;
            }
          }
        else if (
          !originalReceipt ||
          originalReceipt.items.length != receipt.items.length
        )
          isUpdated = true;

        return isUpdated;
      }
    },
    changeItemPrice(item) {
      this.$prompt(
        this.$t("TransactionDetailsChangeItemPricePromptMessage", [item.price]),
        this.$t("TransactionDetailsChangeItemPricePromptTitle"),
        {
          confirmButtonText: this.$t(
            "TransactionDetailsChangeItemPricePromptConfirmBtn"
          ),
          cancelButtonText: this.$t(
            "TransactionDetailsChangeItemPricePromptCancelBtn"
          ),
          inputType: "number",
          inputValue: item.price,
        }
      ).then(({ value }) => {
        item.price = parseFloat(value);
        item.priceDifference = (item.price - item.originalPrice).toFixed(2);
        if (item.priceDifference < 0) {
          item.originalPrice = item.price;
          item.priceDifference = (item.price - item.originalPrice).toFixed(2);
        }
        this.updateReceipts();
      });
    },
    changeItemOriginalPrice(item) {
      this.$prompt(
        this.$t("TransactionDetailsChangeItemFaturaPricePromptMessage", [
          item.price,
        ]),
        this.$t("TransactionDetailsChangeItemFaturaPricePromptTitle"),
        {
          confirmButtonText: this.$t(
            "TransactionDetailsChangeItemPricePromptConfirmBtn"
          ),
          cancelButtonText: this.$t(
            "TransactionDetailsChangeItemPricePromptCancelBtn"
          ),
          inputType: "number",
          inputValue: item.originalPrice,
        }
      ).then(({ value }) => {
        item.originalPrice = parseFloat(value);
        item.priceDifference = (item.price - item.originalPrice).toFixed(2);
        if (item.priceDifference < 0) {
          this.$message.error(
            this.$t("TransactionDetailsChangeItemFaturaPriceErrorMessage")
          );
          item.originalPrice = item.price;
          item.priceDifference = (item.price - item.originalPrice).toFixed(2);
        }
        this.updateReceipts();
      });
    },
    changeItemQuantity(item) {
      this.$prompt(
        this.$t("TransactionDetailsChangeItemQuantityPromptMessage", [
          item.quantity,
        ]),
        this.$t("TransactionDetailsChangeItemQuantityPromptTitle"),
        {
          confirmButtonText: this.$t(
            "TransactionDetailsChangeItemQuantityPromptConfirmBtn"
          ),
          cancelButtonText: this.$t(
            "TransactionDetailsChangeItemQuantityPromptCancelBtn"
          ),
          inputType: "number",
          inputValue: item.quantity,
        }
      ).then(({ value }) => {
        item.quantity = parseInt(value);
        this.updateReceipts();
      });
    },
    changeActualValue(recipt) {
      this.$prompt(
        this.$t("TransactionDetailsChangeActualValuePromptMessage"),
        this.$t("TransactionDetailsChangeActualValuePromptTitle"),
        {
          confirmButtonText: this.$t(
            "TransactionDetailsChangeActualValuePromptConfirmBtn"
          ),
          cancelButtonText: this.$t(
            "TransactionDetailsChangeActualValuePromptCancelBtn"
          ),
          inputType: "number",
          inputErrorMessage: "Invalid input",
        }
      )
        .then(({ value }) => {
          let actualVal = {
            status: "complete",
            actualAmount: Number.parseInt(value),
          };
          // this.$loading();
          transactions
            .updateReceiptActualAmount(recipt.recepitId, actualVal)
            .then((res) => {
              if (res) {
                const index = this.receipts.findIndex(
                  (reciptItem) => reciptItem.recepitId == recipt.recepitId
                );

                this.receipts[index].actualAmount =
                  res.data.receipt.actualAmount;
                this.receipts[index].receiptStatus = res.data.receipt.status;
              }
            })
            .finally(() => {
              // this.$loading().close();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("TransactionDetailsChangeActualValueErrorMessage"),
          });
        });
    },
    stealAnItem({ stolenItem, receipt }) {
      let stolenItemObject = _.find(this.transactionDetails.items, {
        productId: parseInt(stolenItem.productId),
      });
      if (!_.isEmpty(stolenItemObject)) {
        stolenItemObject.price = stolenItem.price;
        stolenItemObject.priceDifference = stolenItem.priceDifference;
        stolenItemObject.distributerId = receipt.receiptDistributer.id;
        stolenItemObject.provider.id = receipt.receiptDistributer.id;
        stolenItemObject.provider.name = receipt.receiptDistributer.name;
        this.updateReceipts();
      }
    },
    onPrintReceipt(receipt) {
      this.isPrintLoading = true;
      if (!receipt.recepitId) {
        this.$notify({
          type: "warning",
          title: this.$t(
            "TransactionDetailsPrintReceiptSaveValidationNotificationTitle"
          ),
          message: this.$t(
            "TransactionDetailsPrintReceiptSaveValidationNotificationMessage"
          ),
        });
        this.isPrintLoading = false;
        return;
      }
      transactions
        .retrivePrintInfo(receipt.recepitId)
        .then(({ receiptPrintDetails }) => {
          if (receiptPrintDetails.status) window.open(receiptPrintDetails.file);
        })
        .catch((error) => {
          this.$notify({
            type: "error",
            title: this.$t("TransactionDetailsPrintErrorTitle"),
            message: error.response.data.error,
          });
        })
        .finally(() => {
          this.isPrintLoading = false;
        });
    },
    changeTransactionStatus() {
      if (!this.receiptsSaved) {
        this.$notify({
          type: "warning",
          title: this.$t(
            "TransactionDetailsChangeTransactionStatusSaveValidationNotificationTitle"
          ),
          message: this.$t(
            "TransactionDetailsChangeTransactionStatusSaveValidationNotificationMessage"
          ),
        });
        return;
      }

      this.$confirm(
        this.$t("TransactionDetailsChangeTransactionStatusPromptMessage"),
        this.$t("TransactionDetailsChangeTransactionStatusPromptTitle"),
        {
          confirmButtonText: this.$t(
            "TransactionDetailsChangeTransactionStatusPromptConfirmBtn"
          ),
          cancelButtonText: this.$t(
            "TransactionDetailsChangeTransactionStatusPromptCancelBtn"
          ),
        }
      ).then(() => {
        // this.$loading();
        //check if transaction  status to go  backward to pending or not

        transactions
          .changeTransactionStatus(this.transactionId, this.nextStatus)
          .then(async () => {
            this.transactionDetails.state = this.nextStatus;
            await this.getTransactionDetails();
            this.$message.success(
              this.$t("TransactionDetailsChangeTransactionStatusSucessMessage")
            );
          })
          .catch(() => {
            this.$message.error(
              this.$t("TransactionDetailsChangeTransactionStatusErrorMessage")
            );
          })
          .finally(() => {
            // this.$loading().close();
          });
      });
    },
    saveInternalNote() {
      // this.$loading();

      try {
        transactions.updateInternalNote(
          this.transactionDetails.user.id,
          this.transactionDetails.user.internalNote
        );
      } catch (error) {
        this.$message.error(
          this.$t("TransactionDetailsSaveInternalNoteErrorMessage")
        );
      } finally {
        // this.$loading().close();
      }
    },
    stopAutoAssignmentClicked() {
      this.autoAssignmentDialogFlag = true;
    },
    async autoAssignmentStopped() {
      this.autoAssignmentDialogFlag = false;
      // await this.getTransactionDetails();
      await transactions
        .getTransactionDetails(this.transactionId)
        .then((res) => {
          this.transactionDetails = this.initTransactionsObject(
            res.transaction
          );
          if (this.transactionDetails.receipts.length) {
            this.receiptsSaved = true;
            this.transactionDetails.receipts = this._processSavedOrderReceipts(
              this.transactionDetails.receipts
            );
            this.originalReceipts = _.cloneDeep(
              this.calculateOrderReceipts(this.transactionDetails.items)
            );
          }
          this.transactionDetails.items = this._processItemPossibleDistributers(
            this.transactionDetails.items
          );
          transactions
            .getTransactionDistributers(this.transactionId)
            .then((res) => {
              this.orderDistributers = manualOrderHandling.flattenOrderDistributers(
                res.transactionDistributes
              );
            });
          this.updateReceipts();
        });
    },
  },
};
</script>

<style lang="scss">
/* global styles */
.el-message-box__btns {
  direction: rtl;

  button:nth-child(2) {
    margin-left: 0;
  }

  button:nth-child(1) {
    margin-left: 10px;
  }
}
</style>
<style lang="scss" scoped>
@import "../styles/wrench-kit/settings/_settings.global.scss";

$right-card-bg-color: #ffc1442e;
.checkbox-item::v-deep {
  .el-checkbox__label {
    font-size: 1rem !important;
    font-weight: bold;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $wrench-color-normal-text-color !important;
  }
  .el-checkbox__inner {
    border-radius: 50%;
    padding: 0.2rem;
  }
  border: none !important;
  color: $wrench-color-normal-text-color !important;
  border-radius: 24px !important;
  background-color: #40579312;
  margin-top: 0.5rem;
}
.spacer {
  height: 1px;
  background: #abb5d0;
  // border: 1px solid #abb5d0;
  opacity: 0.25;
  margin: 1rem 0rem;
}
.c-distributers-header {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.c-distributers-header__title {
  margin-right: auto;
  font-size: 24px;
  font-weight: bold;
}
.small-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.blacklist {
  background: rgb(84, 92, 100);
}
.original {
  background: #85ce61;
}
.receipt {
  background: #cb0300;
}

//Whole page container
.c-transaction-details-container::v-deep {
  .back-btn {
    text-align: left;
    span {
      cursor: pointer;
    }
  }

  .el-page-header__content {
    width: 100%;
  }

  .t-coulumn {
    border-radius: 4px;
    color: $wrench-color-normal-text-color !important;
    font-weight: bold;
    font-size: 0.75rem;
    background-color: #f5f6fa !important;

    &:hover {
      background-color: #f5f6fa !important;
    }
  }

  .el-card {
    border: none;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 4px 4px 0 rgba(43, 45, 55, 0.01);
  }
  .el-collapse-item__header {
    height: auto;
  }
  [class^="reciept-container"] {
    .el-collapse {
      border: none;
      .el-collapse-item__header {
        padding: 2rem;
      }
      //receipt-card-title
      .c-header-title {
        color: $wrench-color-normal-text-color;
      }
      .c-reciept-header {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        .c-header-tags {
          display: flex;
          flex-wrap: wrap;
        }
        .right-container {
          flex-basis: 15%;
          flex-direction: row-reverse;
        }

        [class^="header-tag"] {
          line-height: 19px;

          border-radius: 6px;
          color: #ffffff;
          padding: 1px 9px;
          font-weight: 900;
          margin-right: 0.4rem;
          margin-top: 0.4rem;
          background-color: #545c64;
        }
        [class*="--red"] {
          background-color: #ff595e;
        }
        [class*="--green"] {
          background-color: #0dad2f;
        }
        [class*="--blue"] {
          background-color: #5977ff;
        }
        [class*="--valid"] {
          color: $wrench-color-normal-text-color;
          background-color: #8ac92663;
          padding: 5px;

          font-size: 1.13rem;
          box-shadow: 0 5px 4px 0 rgba(43, 45, 55, 0.03);
        }
        [class*="--unvalid"] {
          background-color: #ff595e;
          padding: 5px;

          font-size: 1.13rem;
          box-shadow: 0 5px 4px 0 rgba(43, 45, 55, 0.03);
        }
      }
      .card-body {
        padding: 30px;
        //before table
        .c-fatura-card__header-buttons {
          .steal-btn {
            flex-basis: 25%;
            .el-button {
              width: 100%;
              justify-content: space-between;
            }
          }
          margin-left: auto;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        table {
          border-spacing: 5px;
          border-collapse: separate;
        }
      }
    }

    //reciecpt container styling
    border: none;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0);
    border-left: 15px solid #fccd13;
    margin: 1rem;
    box-shadow: 0 0 32px 0 rgba(12, 10, 61, 0.04);
  }
  //user reciept item
  [class*="--item"] {
    border-left: 15px solid #545c64;
  }
  //***************************************************************************** */
  //*********** */ transaction infomations*********************************************
  //***************************************************************************** */
  .transaction-info {
    display: flex;
    padding: 11px 15px;
    align-items: center;
    background-color: $right-card-bg-color;

    .t-number {
      color: #ffffff;
      padding: 2px 6px;
      border-radius: 4px;
      margin-right: 1rem;
    }
  }
  .user-info {
    padding: 0px 25px;
    .user-avatar-name {
      display: flex;
      align-items: center;
      .u-avatar {
        border-radius: 14px;

        padding: 8px 15px;
        background-color: $right-card-bg-color;
      }
    }
    .u-name-phone {
      text-align: center;
    }
    .user-area {
      text-align: center;
      font-weight: 600;
      color: #545c64;
    }
  }
  .total-cash {
    background-color: $right-card-bg-color;
    padding: 1em;
    text-align: center;
  }

  .card-btn {
    width: 100%;
    font-weight: 900 !important;
    padding: 13px 0px;
    // background-color: $right-card-bg-color;
  }
  .save-btn {
    color: #545c64;
  }
  .progress-btn {
    background-color: #545c64;
    color: #ffffff;
    margin-top: 0.75rem;
  }
}

// differant direction styles
[dir="rtl"] {
  .reciept-container {
    border: none;
    border-right: 15px solid #fccd13;
  }
  .reciept-container--item {
    border: none;
    border-right: 15px solid #545c64;
  }

  .t-number {
    margin-right: auto;
    margin-left: 1rem;
  }
  .back-btn {
    text-align: right;
  }
  .el-icon-arrow-left {
    transform: rotateY(180deg);
  }
}
</style>
