<template>
  <div>
    <el-input
      v-if="editMode"
      type="textarea"
      :rows="3"
      :placeholder="$t('InternalNoteComponentPlaceholder')"
      :value="value"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
    >
    </el-input>
    <div v-else class="text_container">
      <template v-if="textarea">
        <div>
          <p v-if="!showMore">{{ textarea.slice(0, 95) }}</p>
          <p v-else>{{ textarea }}</p>
          <a
            href="#"
            v-if="textarea.length > 95 && !showMore"
            @click="showMore = true"
          >
            {{ $t("InternalNoteComponentReadMoreLabel") }}</a
          >
          <a href="#" v-if="showMore" @click="showMore = false">
            {{ $t("InternalNoteComponentShowLessLabel") }}</a
          >
        </div>
      </template>
      <template v-else>
        <div class="small-text">
          <p>{{ `Please input user note` }}</p>
        </div>
      </template>
    </div>

    <div v-if="textarea != value || editMode" class="btn-container">
      <el-button
        type="primary"
        icon="el-icon-check"
        size="default"
        @click="checkClicked"
        >{{ $t("internalNoteComponentBtnSave") }}</el-button
      >
      <el-button
        type="danger"
        icon="el-icon-close"
        size="default"
        @click="discardClicked"
        >{{ $t("internalNoteComponentBtnDiscard") }}</el-button
      >
    </div>
    <div class="btn-container" v-else>
      <el-button
        icon="el-icon-edit"
        v-if="!editMode"
        size="mini"
        type="primary"
        @click="editMode = true"
        >{{
          textarea
            ? this.$t("internalNoteComponentBtnEdit")
            : this.$t("internalNoteComponentBtnAdd")
        }}
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: String, default: "" },
  },
  data() {
    return {
      textarea: "",
      editMode: false,
      showMore: false,
    };
  },
  created() {
    this.textarea = this.value;
  },
  methods: {
    checkClicked() {
      this.$emit("check-click");
      this.textarea = this.value;
      this.editMode = false;
    },
    discardClicked() {
      this.value = this.textarea;
      this.editMode = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-container {
  margin-top: 1rem;
  text-align: right;
}
.text_container {
  border: 0.5px solid black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em;

  .small-text {
    opacity: 0.4;
  }
}
</style>
