<template>
  <el-dialog
    :title="$t('TransactionDetailsPageStopAutoAssignmentPopupLabel')"
    :visible.sync="value"
    @close="closeDialogAction"
    width="50%"
  >
    <el-radio-group v-model="radio">
      <template>
        <el-radio
          v-for="item in radioOptions"
          :key="item.label"
          class="u-bottom-margin--2x"
          style="display: block"
          :label="item.value"
          >{{ item.label }}
        </el-radio>
      </template>
    </el-radio-group>

    <el-form v-if="radio === 'Other'">
      <el-form-item
        :label="$t('TransactionDetailsPageStopAutoAssignmentPopupReason')"
        label-width="80px"
      >
        <el-input
          size="mini"
          v-model="textReason"
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelClicked">{{ $t("GlobalsCancelBtn") }}</el-button>
      <el-button type="success" @click="saveClicked">{{
        $t("GlobalsSaveBtn")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { transactions } from "@/services";
export default {
  name: "TransactionDetailsAutoAssignmentCancellationDialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    transactionId: {
      required: true,
    },
  },
  data() {
    return {
      radio: null,
      radioOptions: [
        {
          label: this.$t(
            "TransactionDetailsPageStopAutoAssignmentPopupOption1"
          ),
          value: "Auto-Assignment Bug",
        },
        {
          label: this.$t(
            "TransactionDetailsPageStopAutoAssignmentPopupOption2"
          ),
          value: "Need more Validation",
        },
        {
          label: this.$t(
            "TransactionDetailsPageStopAutoAssignmentPopupOption3"
          ),
          value: "Other",
        },
      ],
      textReason: "",
    };
  },
  methods: {
    cancelClicked() {
      this.closeDialogAction();
    },
    closeDialogAction() {
      this.radio = null;
      this.$emit("input", false);
    },
    async saveClicked() {
      const chosenRadioValue = this.radio;
      const writtenTextValue = this.textReason;
      if (!chosenRadioValue) {
        this.popupMessageWrapper(
          this.$t("TransactionDetailsPageStopAutoAssignmentPopupValidation1"),
          "error",
          3000
        );
        return;
      }
      if (chosenRadioValue == "Other" && writtenTextValue.length == 0) {
        this.popupMessageWrapper(
          this.$t("TransactionDetailsPageStopAutoAssignmentPopupValidation2"),
          "error",
          3000
        );
        return;
      }
      let finalReason;
      if (chosenRadioValue !== "Other") {
        finalReason = chosenRadioValue;
      } else {
        finalReason = writtenTextValue;
      }

      try {
        this.$loading();
        const response = await transactions.stopTransactionAutoAssignment(
          this.transactionId,
          finalReason
        );
        this.$loading().close();
        if (response && response.status == 200) {
          this.popupMessageWrapper(
            this.$t("TransactionDetailsPageStopAutoAssignmentPopupSuccess"),
            "success",
            2500
          );
          setTimeout(() => {
            this.$emit("autoAssignmentStopped");
          }, 2500);
        }
      } catch (error) {
        const errorMessageExists =
          error.response && error.response.data && error.response.data.message;
        let finalErrorMessage = this.$t(
          "RetailerWalletsPageWalletDefaultErrorMessage"
        );
        if (errorMessageExists) {
          finalErrorMessage = error.response.data.message;
        }
        this.popupMessageWrapper(finalErrorMessage, "error", 3000);
        this.$loading().close();
      }
    },
    popupMessageWrapper(message = "", type = "success", duration = 1000) {
      this.$message({ message, type, duration });
    },
  },
  watch: {
    value(newValue) {
      if (newValue === false) {
        this.radio = null;
        this.textReason = "";
      }
    },
  },
};
</script>

<style></style>
